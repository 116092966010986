import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: 'Does this website list all of the private schools in the greater Dallas area?',
      answer: (
        <span>
          No. The 16 schools listed on this site all belong to the Independent School Admission Association of Dallas and are accredited by the Independent Schools Association of the Southwest (ISAS) {' '}
          <a href="https://www.isasw.org" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">isasw.org</a>. To locate other private/parochial schools in the Dallas area, visit {' '}
          <a href="https://www.niche.com/?ref=k12" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Niche.com</a>.
        </span>
      )
    },
    {
      question: 'What are independent schools?',
      answer: (
        <span>
          Independent schools are nonprofit, private schools that are independent in philosophy: each is driven by a unique mission. They are independent from direct control by any church or state. They have the freedom to define their own missions and hire their own teachers and staff. They are also independent in the way they are managed and financed: each is governed by an independent board of trustees and each is primarily supported through tuition payments and charitable contributions. They are accountable to their communities and are accredited by state-approved accrediting bodies.
        </span>
      )
    },
    {
      question: 'How do I decide which school is the right choice for my child?',
      answer: (
        <span>
          Choosing the right school for your child and your family involves considering various factors such as educational philosophy, curriculum, extracurricular activities, school culture, and your child's unique needs and interests. We recommend visiting schools, attending open houses, and speaking with current parents and students to get a better understanding of each school.
        </span>
      )
    },
    {
      question: 'How do I find out what the admission process is for each school?',
      answer: 'Each school has its own admission process, which is typically outlined on their website or admission materials. You can also contact the school\'s admission office directly for more information.'
    },
    {
      question: 'Will my child have to take a test to get into a private school?',
      answer: (
        <span>
          Many private schools require some form of admission testing, such as an entrance exam or an evaluation of academic skills. The specific testing requirements vary from school to school, so it's best to check with each school you're interested in. {' '}
          <Link to="/testing" className="text-blue-500 underline">Click here for information on the kinds of admission testing for ISAAD schools.</Link>
        </span>
      )
    },
    {
      question: 'When does the application process take place?',
      answer: 'The application process typically takes place several months before the start of the academic year for which you are applying. Many schools have specific deadlines for submitting applications, so it\'s important to check with each school for their timeline.'
    },
    {
      question: 'How do I afford private school tuition?',
      answer: (
        <span>
          Private school tuition can be expensive, but many schools offer financial aid and scholarship opportunities to help make it more affordable. You can inquire about financial aid options during the admission process. {' '}
          <a href="https://www.admission.org/advice/demystifying-financial-aid-how-to-afford-private-school" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Click here to learn more - “Demystifying Financial Aid: How to Afford Private School”</a>
        </span>
      )
    },
    {
      question: 'Is there still a Private School Preview in Dallas?',
      answer: (
        <span>
          ISAAD no longer hosts a private school fair. Please see each individual school’s website for dates of campus tours and information sessions. 
        </span>
      )
    },
    {
      question: 'Does ISAAD use a common teacher evaluation for applicants?',
      answer: (
        <span>
          <p>Spring of 2023, ISAAD members collaborated to create a common teacher evaluation (Pre-K, Kinder, 1st - 4th Grade, 5th-12 English, 5th-12th Math) that would be accepted by multiple schools in an effort to lessen the workload of teachers. The following schools accept the ISAAD Common Teacher Evaluation: Alcuin School, Dallas International School, The Episcopal School of Dallas, Good Shepherd Episcopal School, Greenhill School, The Hockaday School, Lakehill Preparatory School, The Lamplighter School, Parish Episcopal School, Shelton School, St. John's Episcopal School, St. Mark's School of Texas, and The Winston School.</p>

          <p>The form is sent directly from the applicant’s current school. School educators/administrators may request forms here (link to https://dev.dallasprivateschool.org/resources).</p>
        </span>
      )
    }
  ];


  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">FAQ</h1>
      <div className="grid grid-cols-1 gap-8">
        <div>
          <div className="bg-white rounded-lg shadow-md">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className={`border-b border-gray-200 last:border-none transition-all ${
                  openIndex === index ? 'bg-gray-100' : ''
                }`}
              >
                <div
                  className="flex justify-between items-center p-4 cursor-pointer"
                  onClick={() => toggleAccordion(index)}
                >
                  <h2 className="text-lg p-2 font-semibold">{faq.question}</h2>
                  <span
                    className={`transform transition-transform ${
                      openIndex === index ? 'rotate-180' : ''
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </span>
                </div>
                {openIndex === index && (
                  <div className="px-4 pb-4 bg-gray-100 rounded-b-lg">
                    <p>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
